import React from 'react';

import logo from '../../assets/images/logo.png';

import './App.scss';

const App = () => {
  function year() {
    return new Date().getFullYear();
  }

  return (
    <div className="landing">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col">
            <img src={logo} alt="Ad Astra Labs Logo" />
          </div>
        </div>
        <div className="row text-center">
          <div className="col-12">
            &copy; Copyright {year()} Ad Astra Labs. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
